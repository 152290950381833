@import url("https://fonts.googleapis.com/css2?family=Playwrite+DE+Grund:wght@100..400&display=swap");
body {
  font-family: "Playwrite DE Grund", cursive;
}
::selection {
  color: rgb(219, 175, 94);
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}
.text-shadow-css {
    border-radius: 26px;
    background: #414141;
    box-shadow:  -5px -5px 10px #1a1a1a,
                 5px 5px 10px #686868;
}



@tailwind base;
@tailwind components;
@tailwind utilities;
